import _ from "lodash";

const INITIAL_STATE = {
  segmentListQCVBR: Array(40).fill(0),
  segmentListCBR: Array(40).fill(0),
  totalSizeQCVBR: 0,
  totalSizeCBR: 0,
};

const segmentDataToKeep = 40;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  let temp = null;
  switch (action.type) {
    case "UPDATE_SEGMENT_LIST_QCVBR":
      temp = [...state.segmentListQCVBR, { size: action.payload }];
      temp = _.drop(temp, temp.length - segmentDataToKeep);
      return {
        ...state,
        segmentListQCVBR: temp,
        totalSizeQCVBR: state.totalSizeQCVBR + action.payload,
      };
    case "UPDATE_SEGMENT_LIST_CBR":
      temp = [...state.segmentListCBR, { size: action.payload }];
      temp = _.drop(temp, temp.length - segmentDataToKeep);
      return {
        ...state,
        segmentListCBR: temp,
        totalSizeCBR: state.totalSizeCBR + action.payload,
      };
    default:
      return state;
  }
};
