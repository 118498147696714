import { combineReducers } from "redux";
import player01State from "./player01State";
import player02State from "./player02State";
import playerLicense from "./playerLicense";
import player01Parameters from "./player01Parameters";
import player02Parameters from "./player02Parameters";
import playbackUrlList from "./playbackUrlList";
import zap from "./zap";
import playbackUrlsInputElement from "./playbackUrlsInputElement";
import segmentStatistics from "./segmentStatistics";
import qcvbrDemoPageVars from "./qcvbrDemoPageVars";

export default combineReducers({
  player01State: player01State,
  player02State: player02State,
  playerLicense: playerLicense,
  player01Parameters: player01Parameters,
  player02Parameters: player02Parameters,
  playbackUrlList: playbackUrlList,
  zap: zap,
  playbackUrlsInputElement: playbackUrlsInputElement,
  segmentStatistics: segmentStatistics,
  qcvbrDemoPageVars: qcvbrDemoPageVars,
});
