import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import * as THEOplayer from '@theoplayer/extended';
import '../../../node_modules/@theoplayer/extended/ui.css';

import './TheoPlayer.css';
import { setHESPLatency, bufferSizeCalc } from './HespLatencyManager';
import { TheoplayerLatencyManager } from './LatencyManager';

const TheoPlayer = ({
  playerState,
  player,
  setPlayer,
  playerParameters,
  setPlayerParameters,
}) => {
  const dispatch = useDispatch();
  const element = useRef(null);
  const canvasElement = useRef(null);
  const [playerBox, setPlayerBox] = useState();

  const wallclockRef = useRef(null);
  const [latencymanagerState, setLatencymanagerState] = useState(null);
  const { playerLibraryLocation, playerLicense } = useSelector(
    (state) => state.playerLicense
  );

  const loadedPlayerParameters = useSelector(
    (state) => state[playerParameters]
  );
  const {
    usecase,
    header,
    catchUpRate,
    timezoneName,
    targetLatency,
    latencySliderVisible,
  } = loadedPlayerParameters;

  const source = useSelector((state) => state[playerState]);
  source.lowLatency = _.get(loadedPlayerParameters, ['lowLatency'], true);

  const writeToCanvas = () => {
    if (canvasElement && player) {
      canvasElement.current.width = `${player.videoWidth}`;
      canvasElement.current.height = `${player.videoHeight}`;
      canvasElement.current.style.width = `${player.ui.currentWidth()}px`;
      canvasElement.current.style.height = `${player.ui.currentHeight()}px`;
      var ctx = canvasElement.current.getContext('2d');
      canvasElement.current.style.display = 'block';
      player.canvas.drawImage(ctx, 0, 0);
    }
  };

  useEffect(() => {
    if (element.current) {
      var playerObject = new THEOplayer.Player(element.current, {
        libraryLocation: playerLibraryLocation,
        license: playerLicense,
        mutedAutoplay: 'all',
        allowMixedContent: true,
      });
      setPlayer(playerObject);

      var latencymanager = undefined;
      if (latencySliderVisible) {
        // eslint-disable-next-line default-case
        switch (source.type) {
          //hesp
          case 'application/vnd.theoplayer.hesp+json':
            setHESPLatency(targetLatency, playerObject);
            break;
          //hls
          case 'application/x-mpegurl':
            try {
              latencymanager = new TheoplayerLatencyManager(
                playerObject,
                targetLatency / 1000,
                catchUpRate,
                true
              );
            } catch {}
            setLatencymanagerState(latencymanager);
            break;
          //dash
          case 'application/dash+xml':
            try {
              latencymanager = new TheoplayerLatencyManager(
                playerObject,
                targetLatency / 1000,
                catchUpRate,
                true
              );
            } catch {}
            setLatencymanagerState(latencymanager);
            break;
        }
      }
      if (source.type === 'application/vnd.theoplayer.hesp+json') {
        playerObject.hesp.latency = {
          windowStart: 0.6,
          target: 0.85,
          windowEnd: 1.1,
          maxOffset: 2,
        };
      }

      playerObject.source = {
        sources: [source],
      };

      var wallclockUpdate = setInterval(() => {
        const now = moment().tz(timezoneName);
        if (!playerObject.paused && wallclockRef.current) {
          wallclockRef.current.textContent = `${('00' + now.hour()).slice(
            -2
          )}:${('00' + now.minutes()).slice(-2)}:${('00' + now.seconds()).slice(
            -2
          )}:${('000' + now.millisecond()).slice(-3)}`;
        }
      }, 1);

      playerObject.addEventListener('timeupdate', () => {
        if (playerObject.buffered.length && !playerObject.paused) {
          let bufferSizeValue = bufferSizeCalc(playerObject) * 1000;
          dispatch(
            setPlayerParameters({ bufferSize: Math.round(bufferSizeValue) })
          );
          dispatch(
            setPlayerParameters({ playbackRate: playerObject.playbackRate })
          );

          let currentLatency = Math.round(
            new Date() - playerObject.currentProgramDateTime
          );
          if (currentLatency < 0) {
            currentLatency = '';
          } else {
            currentLatency = `${currentLatency} ms`;
          }
          dispatch(
            setPlayerParameters({
              currentLatency: currentLatency,
            })
          );
        } else {
          dispatch(
            setPlayerParameters({
              playbackRate: '',
            })
          );
        }
      });

      // COMMENT: canvas is hidden when the first play event is fired
      const firstplay = (event) => {
        if (canvasElement) {
          var canvas = canvasElement.current;
          playerObject.removeEventListener('playing', firstplay);
          canvas.style.display = 'none';
        }
      };

      playerObject.addEventListener('sourcechange', function () {
        playerObject.removeEventListener('playing', firstplay);
        playerObject.addEventListener('playing', firstplay);
      });

      playerObject.preload = 'auto';
      playerObject.abr.strategy = 'quality';
      playerObject.muted = true;
      playerObject.autoplay = true;
    }

    return () => {
      if (latencymanager) {
        latencymanager.disable();
        setLatencymanagerState();
      }
      if (playerObject) {
        playerObject.destroy();
        // setPlayer();
      }
      clearInterval(wallclockUpdate);
    };
    // eslint-disable-next-line
  }, [usecase, header]);

  useEffect(() => {
    if (
      player &&
      source.type === 'application/vnd.theoplayer.hesp+json' &&
      latencySliderVisible
    ) {
      setHESPLatency(targetLatency, player);
    }
    if (
      latencySliderVisible &&
      latencymanagerState &&
      (source.type === 'application/x-mpegurl' ||
        source.type === 'application/dash+xml')
    ) {
      latencymanagerState._targetLatency = targetLatency / 1000;
      latencymanagerState.LATENCY_CATCHUP_RATE = catchUpRate;

      // latencymanagerState.disable();
      // try {
      //   var latencymanager = new TheoplayerLatencyManager(
      //     player,
      //     targetLatency / 1000,
      //     catchUpRate,
      //     true
      //   );
      // } catch {}

      // setLatencymanagerState(latencymanager);
    }
    // return () => {
    //   if (
    //     latencymanager &&
    //     (source.type === 'application/x-mpegurl' ||
    //       source.type === 'application/dash+xml')
    //   ) {
    //     latencymanager.disable();
    //     setLatencymanagerState();
    //   }
    // };
    // eslint-disable-next-line
  }, [catchUpRate, targetLatency]);
  //zapping
  const zapperIndex = useSelector((state) => state.zap.zapperIndex);
  const previousZapperIndex = useRef(zapperIndex);

  useEffect(() => {
    if (player) {
      const startTime = performance.now();
      let isZappingTimeUpdated = false;

      try {
        var playerZappingTime = player.addEventListener('playing', function () {
          if (!isZappingTimeUpdated) {
            let zappingTime = Math.floor(performance.now() - startTime);
            dispatch(
              setPlayerParameters({
                zappingTime: `${zappingTime} ms`,
              })
            );
            isZappingTimeUpdated = true;
          }
        });
      } catch {}
    }
    return () => {
      if (player) {
        try {
          player.removeEventListener(playerZappingTime);
        } catch {}
      }
    };
    // eslint-disable-next-line
  }, [zapperIndex]);

  useEffect(() => {
    if (player && previousZapperIndex.current !== zapperIndex) {
      writeToCanvas();
      player.src = [source];
      // setPlayerBox({ visibility: 'hidden' });
      // setTimeout(() => {
      //   console.log('khfkjskjfhkjsdjkfhskj');
      //   player.src = [source];
      // }, 50);

      var removePlayerBox = player.addEventListener('playing', () => {
        // setPlayerBox({ visibility: 'visible' });
        try {
          player.removeEventListener(removePlayerBox);
        } catch {}
      });

      previousZapperIndex.current = zapperIndex;
    }
    return () => {
      if (player) {
        try {
          player.removeEventListener(removePlayerBox);
        } catch {}
      }
    };
    // eslint-disable-next-line
  }, [source.src]);

  const wallclockFontSize = () => {
    if (element.current) {
      const playerHeight = element.current.offsetHeight;
      const clockFontSize = Math.round(playerHeight / 18);
      wallclockRef.current.style.fontSize = `${clockFontSize}px`;
    }
  };

  return (
    <div className="theoplayer">
      <div style={playerBox} className="theoplayer-player">
        <div
          className={
            'theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer'
          }
          ref={element}
          style={{ width: '100%', height: '100%' }}
        >
          {wallclockFontSize()}
          <canvas
            ref={canvasElement}
            style={{ width: '1280px', height: '720px' }}
          ></canvas>
        </div>
      </div>
      <div className="theoplayer-wallclock" ref={wallclockRef}></div>
    </div>
  );
};

export default TheoPlayer;
