import React, { useState, useEffect } from 'react';
import isUrl from 'is-url';
import { useDispatch } from 'react-redux';
import { Accordion, Icon, Dropdown } from 'semantic-ui-react';

import { setPlaybackUrlsInputElement } from '../../actions';

const UrlInput = () => {
  const dispatch = useDispatch();
  const [dashUrl, setDashUrl] = useState('');
  const [hlsUrl, setHlsUrl] = useState('');
  const [hespUrl, setHespUrl] = useState('');
  const formSize = { width: '40vw', marginBottom: '30px' };
  const [dropDownActive, setDropDownActive] = useState(false);

  useEffect(() => {
    dispatch(setPlaybackUrlsInputElement({ timezone: 'Europe/Brussels' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timezones = [
    { text: 'Europe/Brussels', value: 'Europe/Brussels' },
    { text: 'America/New_York', value: 'America/New_York' },
    { text: 'America/Los_Angeles', value: 'America/Los_Angeles' },
  ];

  const getTimezone = (event, { value }) => {
    dispatch(setPlaybackUrlsInputElement({ timezone: value }));
  };

  const handleClick = () => {
    if (dropDownActive) {
      setDropDownActive(false);
    } else {
      setDropDownActive(true);
    }
  };

  const getDashUrl = (value) => {
    setDashUrl(value);
    if (isUrl(value)) {
      dispatch(setPlaybackUrlsInputElement({ dashPlaybackUrl: value }));
    }
  };
  const getHlsUrl = (value) => {
    setHlsUrl(value);
    if (isUrl(value)) {
      dispatch(setPlaybackUrlsInputElement({ hlsPlaybackUrl: value }));
    }
  };
  const getHespUrl = (value) => {
    setHespUrl(value);
    if (isUrl(value)) {
      dispatch(setPlaybackUrlsInputElement({ hespPlaybackUrl: value }));
    }
  };

  return (
    <Accordion>
      <Accordion.Title active={dropDownActive} onClick={handleClick}>
        <Icon name="dropdown" />
        Enter Playback URLs manually
      </Accordion.Title>
      <Accordion.Content active={dropDownActive}>
        <div style={formSize}>
          <div className="search-bar ui segment">
            <form className="ui form">
              <div className="field">
                <label>DASH URL</label>
                <div className="ui input error">
                  <input
                    type="text"
                    placeholder="url"
                    value={dashUrl}
                    onChange={(event) => getDashUrl(event.target.value)}
                  />
                </div>
                <br />
                <label>HLS URL</label>
                <div className="ui input error">
                  <input
                    type="url"
                    placeholder="url"
                    value={hlsUrl}
                    onChange={(event) => getHlsUrl(event.target.value)}
                  />
                </div>
                <br />
                <label>HESP URL</label>
                <div className="ui input error">
                  <input
                    type="text"
                    placeholder="url"
                    value={hespUrl}
                    onChange={(event) => getHespUrl(event.target.value)}
                  />
                </div>
                <br />
                <label>Timezone</label>
                <Dropdown
                  placeholder="Europe/Brussels"
                  search
                  selection
                  options={timezones}
                  onChange={getTimezone}
                ></Dropdown>
              </div>
            </form>
          </div>
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default UrlInput;
