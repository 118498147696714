const INITIAL_STATE = {
  src: 'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/dash/low-latency/master.mpd',
  type: 'application/dash+xml',
  lowLatency: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PLAYBACK_URL02_CHANGE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
