import React from 'react';
import { Popup, Dropdown } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import styles from './ControlItems.module.css';
import LatencySlider from '../LatencySlider/LatencySlider';

const ContolItems = ({
  playerState,
  setPlayerParameters,
  playerParameters,
}) => {
  const dispatch = useDispatch();
  //get playback url
  const src = useSelector((state) => state[playerState].src);
  //get qr code
  const qrCode = (
    <img
      alt="none"
      src={` https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${src}`}
    />
  );
  //playback url and copy button
  const videoStreamLink = (
    <a className={styles.videolink} href={src} rel="noreferrer" target="_blank">
      video stream link
    </a>
  );

  //playback copy button
  const copyToClipboard = () => {
    var textField = document.createElement('textarea');
    textField.innerText = src;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };
  const videoCopyButton = (
    <p onClick={copyToClipboard}>copy url to clipboard</p>
  );

  const dropDownValue = [
    { key: 1, text: videoStreamLink },
    { key: 2, text: videoCopyButton },
  ];

  //get player parameters
  const playerParametersVars = useSelector((state) => state[playerParameters]);
  const visibility = playerParametersVars.latencySliderVisible
    ? {}
    : { display: 'none' };

  return (
    <div className={styles.controlItemsGrid}>
      <div className={styles.links}>
        <Popup
          content={qrCode}
          trigger={<p className={styles.videolink}>qr code</p>}
        />
        <Dropdown options={dropDownValue} text="video stream link" />
      </div>
      <div className={styles.info} style={visibility}>
        <p>{`Current player latency: ${playerParametersVars.currentLatency}`}</p>
        <p>{`Playback rate: ${playerParametersVars.playbackRate}`}</p>
      </div>
      <div className={styles.slider01} style={visibility}>
        <LatencySlider
          setTargetPlayerLatency={setPlayerParameters}
          targetPlayerLatency={playerParametersVars.targetLatency}
        />
      </div>
      <div className={styles.slider02} style={visibility}>
        <Slider
          value={playerParametersVars.catchUpRate}
          onChange={(e) => {
            dispatch(
              setPlayerParameters({
                catchUpRate: e,
              })
            );
          }}
          min={0.08}
          max={0.3}
          step={0.01}
        />
        <p>{`Latency CatchUp Rate: ${playerParametersVars.catchUpRate} %`}</p>
      </div>
    </div>
  );
};

export default ContolItems;
