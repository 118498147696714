const dashPlaybackUrl01 =
  'http://18.135.43.11:2001/dash/SkyMainEvent/MainEventCMAF.mpd';
const dashPlaybackUrl02 =
  'http://18.135.43.11:2001/dash/SkyPremLge/PremLgeCMAF.mpd';
const hlsUrlPlaybackUrl01 = 'https://ovpllpoc.ovpobs.tv/llhls/sdi8/SDI8.m3u8';
const hlsUrlPlaybackUrl02 = 'https://ovpllpoc.ovpobs.tv/llhls/sdi7/SDI7LL.m3u8';
const hespUrlFastly01 = 'https://ovpllpoc.ovpobs.tv/hesp/sdi8/SDI8HESP.json';
const hespUrlFastly02 = 'https://ovpllpoc.ovpobs.tv/hesp/sdi7/SDI7HESP.json';

export {
  dashPlaybackUrl01,
  dashPlaybackUrl02,
  hlsUrlPlaybackUrl01,
  hlsUrlPlaybackUrl02,
  hespUrlFastly01,
  hespUrlFastly02,
};
