const INITIAL_STATE = {
  dashPlaybackUrl: '',
  hlsPlaybackUrl: '',
  hespPlaybackUrl: '',
  timezone: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PLAYBACKURL_FROM_INPUT_ELEMENT':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
