import React from 'react';
import Slider from './Slider';

const LatencySlider = ({ setTargetPlayerLatency, targetPlayerLatency }) => {
  return (
    <div>
      <Slider
        steps={[10, 50, 100]}
        min={50}
        max={10000}
        setTargetPlayerLatency={setTargetPlayerLatency}
        targetPlayerLatency={targetPlayerLatency}
      />
      <p>{`Target player latency: ${targetPlayerLatency} ms`}</p>
    </div>
  );
};

export default LatencySlider;
