const setHESPLatency = (latencyValue, player) => {
  const num1 = latencyValue / 1000;
  const num2 = 0.66 * num1;
  const num3 = 1.33 * num1;

  player.hesp.latency.windowStart = num2;
  player.hesp.latency.target = num1;
  player.hesp.latency.windowEnd = num3;
  player.hesp.latency.maxOffset = 0;
};

const bufferSizeCalc = (playerInst) => {
  let bufferedSize;
  bufferedSize =
    playerInst.buffered.end(playerInst.buffered.length - 1) -
    playerInst.currentTime;
  return bufferedSize.toFixed(2);
};

const addHESPEventListeners = (player, bufferSizeRef, playbackRateRef) => {
  player.addEventListener('timeupdate', () => {
    if (bufferSizeRef.current && playbackRateRef.current) {
      if (player.buffered.length && !player.paused) {
        let bufferSizeValue = bufferSizeCalc(player) * 1000;
        bufferSizeRef.current.textContent = ` ${Math.round(bufferSizeValue)}`;
        playbackRateRef.current.textContent = ` ${player.playbackRate}`;
      } else {
        playbackRateRef.current.textContent = '';
      }
    }
  });
};

export { setHESPLatency, bufferSizeCalc, addHESPEventListeners };
