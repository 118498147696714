import React, { useState, useRef, useEffect } from 'react';
import { Button, Dropdown, Table } from 'semantic-ui-react';

import { getlistUsers, deleteUser, updateUser, addUser } from '../../api/axios';
import styles from './Users.module.css';

const Users = () => {
  const [usersTable, setUsersTable] = useState();
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLasttName] = useState('');
  const [email, setEmail] = useState('');
  const [userRights, setUserRights] = useState('');
  const [userId, setUserId] = useState();
  const [password01, setPassword01] = useState('');
  const [password02, setPassword02] = useState('');

  const editButton = (user) => {
    setUsername(user.username);
    setFirstName(user.details.firstName);
    setLasttName(user.details.lastName);
    setEmail(user.details.email);
    setUserRights(user.userRights);
    setUserId(user.id);
  };
  const deleteButton = (id) => {
    console.log(id);
  };

  const typeUsername = (typedUsername) => {
    setUsername(typedUsername);
  };

  const typeFirstName = (typedFirstName) => {
    setFirstName(typedFirstName);
  };

  const typeLastName = (typedLastName) => {
    setLasttName(typedLastName);
  };

  const typeEmail = (typedEmail) => {
    setEmail(typedEmail);
  };

  const typePassword01 = (typedPassword) => {
    setPassword01(typedPassword);
  };

  const typePassword02 = (typedPassword) => {
    setPassword02(typedPassword);
  };

  const changeUserRights = (changedUserRights) => {
    setUserRights(changedUserRights);
  };

  const saveUserData = async () => {
    if (userId && username) {
    } else {
      if (password01 && password01 === password02) {
        console.log('userId', userId);
        await addUser({
          username: username,
          password: password01,
          userRighs: userRights,
          firstName: firstName,
          lastName: lastName,
          email: email,
        });
      }
    }
    getUsers();
  };

  const getUsers = async () => {
    const users = await getlistUsers();
    const result = users.map((user) => {
      return (
        <Table.Row key={user.id}>
          <Table.Cell textAlign="center">{user.id}</Table.Cell>
          <Table.Cell textAlign="center">{user.username}</Table.Cell>
          <Table.Cell textAlign="center">{user.details.firstName}</Table.Cell>
          <Table.Cell textAlign="center">{user.details.lastName}</Table.Cell>
          <Table.Cell textAlign="center">{user.details.email}</Table.Cell>
          <Table.Cell textAlign="center">{user.userRights}</Table.Cell>
          <Table.Cell textAlign="center">
            {user.details.numberOfEntries}
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Button
              primary
              style={{ width: '90px' }}
              onClick={() => editButton(user)}
            >
              {' '}
              Edit
            </Button>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Button
              color="red"
              style={{ width: '90px' }}
              onClick={() => deleteButton(user.id)}
            >
              Delete
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    setUsersTable(result);
  };

  useEffect(() => {
    getUsers();
  });

  return (
    <div className={styles.container}>
      <div className="ui fluid labeled input">
        <div style={{ width: '100px' }} className="ui label">
          Username
        </div>
        <input
          type="text"
          onChange={(event) => typeUsername(event.target.value)}
          value={username}
        ></input>
      </div>
      <div className={styles.passwordInput}>
        <div style={{ marginTop: '10px' }} className="ui labeled input">
          <div style={{ width: '137px' }} className="ui label">
            Password
          </div>
          <input
            type="password"
            value={password01}
            onChange={(event) => typePassword01(event.target.value)}
          ></input>
        </div>
        <div style={{ marginTop: '10px' }} className="ui labeled input">
          <div style={{ width: '137px' }} className="ui label">
            Repeat Password
          </div>
          <input
            type="password"
            value={password02}
            onChange={(event) => typePassword02(event.target.value)}
          ></input>
        </div>
      </div>
      <div style={{ marginTop: '10px' }} className="ui fluid labeled input">
        <div style={{ width: '100px' }} className="ui label">
          First Name
        </div>
        <input
          type="text"
          value={firstName}
          onChange={(event) => typeFirstName(event.target.value)}
        ></input>
      </div>
      <div style={{ marginTop: '10px' }} className="ui fluid labeled input">
        <div style={{ width: '100px' }} className="ui label">
          Last Name
        </div>
        <input
          type="text"
          value={lastName}
          onChange={(event) => typeLastName(event.target.value)}
        ></input>
      </div>
      <div style={{ marginTop: '10px' }} className="ui fluid labeled input">
        <div style={{ width: '100px' }} className="ui label">
          Email
        </div>
        <input
          type="email"
          value={email}
          onChange={(event) => typeEmail(event.target.value)}
        ></input>
      </div>
      <Dropdown
        style={{ marginTop: '10px' }}
        placeholder="User Rights"
        fluid
        selection
        simple
        value={userRights}
        onChange={(event) => changeUserRights(event.target.value)}
        options={[
          { key: 'admin', text: 'Admin', value: 'admin' },
          { key: 'user', text: 'User', value: 'user' },
        ]}
      />
      <div className={styles.saveButtonContainer}>
        <Button color="teal" style={{ width: '90px' }} onClick={saveUserData}>
          Save
        </Button>
      </div>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">id</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Username</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">First Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Last Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Email</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">User Rights</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Number of Entries
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Edit</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{usersTable}</Table.Body>
      </Table>
    </div>
  );
};

export default Users;
