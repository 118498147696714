const INITIAL_STATE = {
  zapperIndex: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ZAPPING':
      // eslint-disable-next-line default-case
      switch (state.zapperIndex) {
        case 0:
          return { zapperIndex: 1 };
        case 1:
          return { zapperIndex: 0 };
      }
      break;
    default:
      return state;
  }
};
