import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './PlayerPage.css';
import TheoPLayer from '../TheoPlayer/TheoPlayer';
import DashReferencePlayer from '../DashReferencePlayer/DashReferencePlayer';
import ControlElements from '../ControlElements/ControlElements';
import { setPlayer01Parameters, setPlayer02Parameters } from '../../actions';

const PlayerPage = () => {
  //player01
  const [player01, setPlayer01] = useState();
  const player01Parameters = useSelector((state) => state.player01Parameters);
  const header01 = useSelector((state) => state.player01Parameters.header);

  //player02
  const [player02, setPlayer02] = useState();
  const player02Parameters = useSelector((state) => state.player02Parameters);
  const header02 = useSelector((state) => state.player02Parameters.header);
  const playerType = () => {
    return header02 === 'DASH Standard Latency' ? (
      <DashReferencePlayer
        playerState={'player02State'}
        player={player02}
        setPlayer={setPlayer02}
        playerParameters={'player02Parameters'}
        setPlayerParameters={setPlayer02Parameters}
      />
    ) : (
      <TheoPLayer
        playerState={'player02State'}
        player={player02}
        setPlayer={setPlayer02}
        playerParameters={'player02Parameters'}
        setPlayerParameters={setPlayer02Parameters}
      />
    );
  };

  useEffect(() => {
    //make an hesp player reference to window object
    window.player01 = player01;
  }, [player01]);

  useEffect(() => {
    //make an hesp player reference to window object
    window.player02 = player02;
  }, [player02]);

  return (
    <div className="flex-container">
      <div className="flex-playerPage">
        {player01Parameters.playerVisible && (
          <div className="flex-playerPage-row">
            <h2>{header01}</h2>
            <div>
              {player01Parameters.playerVisible && (
                <TheoPLayer
                  playerState={'player01State'}
                  player={player01}
                  setPlayer={setPlayer01}
                  playerParameters={'player01Parameters'}
                  setPlayerParameters={setPlayer01Parameters}
                />
              )}
            </div>
          </div>
        )}
        {player02Parameters.playerVisible && (
          <div className="flex-playerPage-row">
            <h2>{header02}</h2>
            <div>{player02Parameters.playerVisible && playerType()}</div>
          </div>
        )}
      </div>
      <ControlElements />
    </div>
  );
};

export default PlayerPage;
