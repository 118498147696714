import PlayerPage from '../PlayerPage/PlayerPage';

const menuItems01 = {
  DASH: {
    cssClassName: 'item',
    content: <PlayerPage />,
    player01: {
      header: 'Chunked DASH Low Latency',
      usecase: 'DASH',
      playerVisible: true,
      zapElementVisible: false,
      latencySliderVisible: true,
      catchUpRateSliderVisible: true,
      targetLatency: 2000,
      catchUpRate: 0.08,
    },
    player02: {
      usecase: 'DASH',
      playerVisible: false,
      zapElementVisible: false,
      latencySliderVisible: false,
      catchUpRateSliderVisible: false,
      catchUpRate: 0.08,
    },
  },
  'DASH LL vs Std': {
    cssClassName: 'item',
    content: <PlayerPage />,
    player01: {
      header: 'Chunked DASH Low Latency',
      usecase: 'DASH',
      playerVisible: true,
      zapElementVisible: false,
      latencySliderVisible: true,
      catchUpRateSliderVisible: true,
      targetLatency: 2000,
      catchUpRate: 0.08,
    },
    player02: {
      header: 'DASH Standard Latency',
      usecase: 'DASH Standard Latency',
      playerVisible: true,
      zapElementVisible: false,
      latencySliderVisible: false,
      catchUpRateSliderVisible: false,
      targetLatency: 2000,
      lowLatency: false,
      catchUpRate: 0.08,
    },
  },
  HLS: {
    cssClassName: 'item',
    content: <PlayerPage />,
    player01: {
      header: 'HLS low latency',
      usecase: 'HLS',
      playerVisible: true,
      zapElementVisible: false,
      latencySliderVisible: true,
      catchUpRateSliderVisible: true,
      targetLatency: 3000,
    },
    player02: {
      usecase: 'HLS',
      playerVisible: false,
      zapElementVisible: false,
      latencySliderVisible: false,
      catchUpRateSliderVisible: false,
      catchUpRate: 0.08,
    },
  },
  HESP: {
    cssClassName: 'item',
    content: <PlayerPage />,
    player01: {
      header: 'HESP',
      usecase: 'HESP',
      playerVisible: true,
      zapElementVisible: true,
      latencySliderVisible: false,
      catchUpRateSliderVisible: false,
      targetLatency: 500,
      catchUpRate: 0.08,
    },
    player02: {
      usecase: 'HESP',
      playerVisible: false,
      zapElementVisible: false,
      latencySliderVisible: false,
      catchUpRateSliderVisible: false,
      catchUpRate: 0.08,
    },
  },
  'HESP vs DASH': {
    cssClassName: 'item',
    content: <PlayerPage />,
    player01: {
      header: 'HESP',
      usecase: 'HESP',
      playerVisible: true,
      zapElementVisible: true,
      latencySliderVisible: false,
      catchUpRateSliderVisible: false,
      targetLatency: 2000,
      catchUpRate: 0.08,
    },
    player02: {
      header: 'DASH',
      usecase: 'HESP vs DASH',
      playerVisible: true,
      zapElementVisible: true,
      latencySliderVisible: true,
      catchUpRateSliderVisible: false,
      targetLatency: 2000,
      catchUpRate: 0.08,
    },
  },
};
const menuItems02 = {
  'Channel Zapping': {
    cssClassName: 'item',
    link: 'channel-zapping',
  },
  'Multi Camera': {
    cssClassName: 'item',
    link: 'multi-camera',
  },
};

export { menuItems01, menuItems02 };
