const getPlaybackUrl = (url) => {
  if (url === null) {
    url = "";
  }
  const findExtention = url.split(".");
  const extention = findExtention[findExtention.length - 1];
  let source = {};
  // eslint-disable-next-line default-case
  switch (extention.toLowerCase()) {
    case "mpd":
      source = {
        src: url,
        type: "application/dash+xml",
        lowLatency: true,
      };
      break;
    case "m3u8":
      source = {
        src: url,
        type: "application/x-mpegurl",
        lowLatency: true,
      };
      break;
    case "json":
      source = {
        src: url,
        type: "application/vnd.theoplayer.hesp+json",
        lowLatency: true,
      };
      break;
  }
  return source;
};

const playback01UrlFromQuery = (url) => {
  return {
    type: "PLAYBACK_URL01_CHANGE",
    payload: getPlaybackUrl(url),
  };
};

const playback02UrlFromQuery = (url) => {
  return {
    type: "PLAYBACK_URL02_CHANGE",
    payload: getPlaybackUrl(url),
  };
};

const playback01UrlFromHtml = (source) => {
  return {
    type: "PLAYBACK_URL01_CHANGE",
    payload: source,
  };
};

const playback02UrlFromHtml = (source) => {
  return {
    type: "PLAYBACK_URL02_CHANGE",
    payload: source,
  };
};

const updatePlayerLicense = (libraryLocation, license) => {
  return {
    type: "PLAYBACK_LICENSE_CHANGE",
    payload: {
      playerLibraryLocation: libraryLocation,
      playerLicense: license,
    },
  };
};

const setPlayer01Parameters = (object) => {
  return {
    type: "SET_PLAYER01_PARAMETERS",
    payload: object,
  };
};

const setPlayer02Parameters = (object) => {
  return {
    type: "SET_PLAYER02_PARAMETERS",
    payload: object,
  };
};

const setZapperIndex = () => {
  return {
    type: "ZAPPING",
    payload: undefined,
  };
};

const setPlaybackUrlsInputElement = (element) => {
  return {
    type: "SET_PLAYBACKURL_FROM_INPUT_ELEMENT",
    payload: element,
  };
};

const updateSegmentListQCVBR = (data) => {
  return {
    type: "UPDATE_SEGMENT_LIST_QCVBR",
    payload: data,
  };
};

const updateSegmentListCBR = (data) => {
  return {
    type: "UPDATE_SEGMENT_LIST_CBR",
    payload: data,
  };
};

const setCurrentServiceQCVBRDemo = (serviceName) => {
  if (serviceName === "rugby") {
    return {
      type: "SET_SERVICE_RUGBY",
    };
  }
  if (serviceName === "scenic") {
    return {
      type: "SET_SERVICE_SCENIC",
    };
  }
};

export {
  playback01UrlFromQuery,
  playback02UrlFromQuery,
  playback01UrlFromHtml,
  playback02UrlFromHtml,
  updatePlayerLicense,
  setPlayer01Parameters,
  setPlayer02Parameters,
  setZapperIndex,
  setPlaybackUrlsInputElement,
  updateSegmentListQCVBR,
  updateSegmentListCBR,
  setCurrentServiceQCVBRDemo,
};
