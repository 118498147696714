import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FrontPage from './FrontPage/FrontPage';
import {
  playback01UrlFromQuery,
  playback02UrlFromQuery,
  playback01UrlFromHtml,
  playback02UrlFromHtml,
  updatePlayerLicense,
  setPlayer01Parameters,
  setPlayer02Parameters,
} from '../actions';

const App = ({ region }) => {
  const dispatch = useDispatch();
  const search = useLocation().search;

  //set region
  // eslint-disable-next-line
  switch (region) {
    case 'na':
      dispatch(
        setPlayer01Parameters({
          region: 'NA',
          timezoneName: 'America/New_York',
        })
      );
      dispatch(
        setPlayer02Parameters({
          region: 'NA',
          timezoneName: 'America/New_York',
        })
      );
      break;
    case 'eu':
      dispatch(
        setPlayer01Parameters({
          region: 'EU',
          timezoneName: 'Europe/Brussels',
        })
      );
      dispatch(
        setPlayer02Parameters({
          region: 'EU',
          timezoneName: 'Europe/Brussels',
        })
      );
      break;
      case 'asia':
        dispatch(
          setPlayer01Parameters({
            region: 'ASIA',
            timezoneName: 'Asia/Singapore',
          })
        );
        dispatch(
          setPlayer02Parameters({
            region: 'ASIA',
            timezoneName: 'Asia/Singapore',
          })
        );
        break;
  }

  //read url from index.html
  const source01 = window.source01;
  const source02 = window.source02;
  dispatch(playback01UrlFromHtml(source01));
  dispatch(playback02UrlFromHtml(source02));

  const libraryLocation = window.libraryLocation;
  const license = window.license;
  dispatch(updatePlayerLicense(libraryLocation, license));

  //read url from query string
  const url01 = new URLSearchParams(search).get('url01');
  const url02 = new URLSearchParams(search).get('url02');
  dispatch(playback01UrlFromQuery(url01));
  dispatch(playback02UrlFromQuery(url02));

  return <FrontPage />;
};

export default App;
