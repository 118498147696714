const INITIAL_STATE = {
  src: undefined,
  type: undefined,
  lowLatency: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PLAYBACK_URL01_CHANGE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
