import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

import { getStatisticsfromInfluxDB } from "../../../api/qcvbr-server-api";

const BarChart = () => {
  const [dataset, setDataset] = useState([]);

  // eslint-disable-next-line
  const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font || getComputedStyle(document.body).font;
    return context.measureText(text).width;
  };
  const { totalSizeQCVBR, totalSizeCBR } = useSelector(
    (state) => state.segmentStatistics
  );
  const { serviceName } = useSelector((state) => state.qcvbrDemoPageVars);

  const data = {
    // labels: ['Min/Max QC-VBR', 'Min/Max CBR', 'Mode QC-VBR', 'Mode CBR'],
    datasets: [
      {
        // labels: ['Min/Max QC-VBR', 'Min/Max CBR', 'Mode QC-VBR', 'Mode CBR'],
        backgroundColor: [
          "rgba(255,99,132, 0.2)",
          "rgba(230,145,56, 0.8)",
          "rgba(61,133,198, 0.8)",
          "rgba(106,168,79, 0.8)",
        ],
        data: dataset,
        maxBarThickness: 30,
        yAxisID: "y",
      },
      {
        // labels: ['Total Size QC-VBR, Total Size CBR'],
        backgroundColor: ["rgba(204,0,0, 0.8)", "rgba(56,118,29, 0.8)"],
        data: [
          { x: "Total Size QC-VBR", y: [0, totalSizeQCVBR] },
          { x: "Total Size CBR", y: [0, totalSizeCBR] },
          { x: "", y: [0, 0] },
        ],
        maxBarThickness: 30,
        yAxisID: "y1",
      },
    ],
  };

  //set default font
  // Chart.defaults.font.family = "Roboto";
  // Chart.defaults.font.size = "12rem";

  const options = {
    animation: {
      duration: 1,
      onComplete: function ({ chart }) {
        // console.log(chart.config.data.datasets[0].data)
        const ctx = chart.ctx;
        // ctx.textAlign = 'center'
        // ctx.save();
        // const fontSize = 12;
        // ctx.font = `${fontSize}px "Helvetica Neue", "Helvetica", "Arial", sans-serif`;

        //add notes
        // const {
        //   chartArea: { left, right, top, bottom, width, height },
        // } = chart;
        // ctx.font = `${fontSize}px "Helvetica Neue", "Helvetica", "Arial", sans-serif`;
        // ctx.fillText("TEST", width / 4 - left, bottom);

        chart.config.data.datasets.forEach(function (dataset, i) {
          const meta = chart.getDatasetMeta(i);
          // console.log(meta.data.)

          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];

            switch (data.x) {
              case "Min/Max QC-VBR":
                ctx.fillText(`min: ${data.y[0]}`, bar.x + 30, bar.y + 45);
                ctx.fillText(`max: ${data.y[1]}`, bar.x + 30, bar.y + 30);
                break;
              case "Min/Max CBR":
                ctx.fillText(`min: ${data.y[0]}`, bar.x + 30, bar.y + 45);
                ctx.fillText(`max: ${data.y[1]}`, bar.x + 30, bar.y + 30);
                break;
              case "Mode QC-VBR":
                ctx.fillText(`${data.y[1]}`, bar.x + 30, bar.y + 30);
                break;
              case "Mode CBR":
                ctx.fillText(`${data.y[1]}`, bar.x + 30, bar.y + 30);
                break;
              case "Total Size QC-VBR":
                ctx.fillText(
                  `${(data.y[1] / 1024 / 1024).toFixed(1)} MB`,
                  bar.x + 30,
                  bar.y + 30
                );
                break;
              case "Total Size CBR":
                ctx.fillText(
                  `${(data.y[1] / 1024 / 1024).toFixed(1)} MB`,
                  bar.x + 30,
                  bar.y + 30
                );
                break;
            }
          });
        });
      },
    },
    plugins: {
      title: {
        display: true,
        text: "pVAMF statistcs for last 24h",
      },
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
      y1: {
        grid: {
          display: false,
        },
        position: "right",
      },
    },
    // layout: {
    //   padding: 20,
    // },
  };

  useEffect(() => {
    getStatisticsfromInfluxDB()
      .then((response) => {
        setDataset([
          {
            x: "Min/Max QC-VBR",
            y: [
              response.minValue[`${serviceName}-qcvbr`],
              response.maxValue[`${serviceName}-qcvbr`],
            ],
          },
          {
            x: "Min/Max CBR",
            y: [
              response.minValue[`${serviceName}-cbr`],
              response.maxValue[`${serviceName}-cbr`],
            ],
          },
          {
            x: "Mode QC-VBR",
            y: [0, response.modeValue[`${serviceName}-qcvbr`]],
          },
          { x: "Mode CBR", y: [0, response.modeValue[`${serviceName}-cbr`]] },
        ]);
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
};
export default BarChart;
