const rugby_url_qcvbr =
  "https://vn-public-lwr-dmz.demo.synamedia.co.uk/content/dash/rugby-qcvbr/master.mpd";
const rugby_url_cbr =
  "https://vn-public-atl-dmz.demo.synamedia.co.uk/content/dash/rugby-cbr/master.mpd";
const scenic_url_qcvbr =
  "https://vn-public-lwr-dmz.demo.synamedia.co.uk/content/dash/scenic-qcvbr/master.mpd";
const scenic_url_cbr =
  "https://vn-public-atl-dmz.demo.synamedia.co.uk/content/dash/scenic-cbr/master.mpd";

const INITIAL_STATE = {
  serviceName: "scenic",
  url_qcvbr:
    "https://vn-public-lwr-dmz.demo.synamedia.co.uk/content/dash/scenic-qcvbr/master.mpd",
  url_cbr:
    "https://vn-public-atl-dmz.demo.synamedia.co.uk/content/dash/scenic-cbr/master.mpd",
};

const segmentDataToKeep = 40;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SERVICE_RUGBY":
      return {
        ...state,
        ...{ url_qcvbr: rugby_url_qcvbr },
        ...{ url_cbr: rugby_url_cbr },
        ...{ serviceName: "rugby" },
      };
    case "SET_SERVICE_SCENIC":
      return {
        ...state,
        ...{ url_qcvbr: scenic_url_qcvbr },
        ...{ url_cbr: scenic_url_cbr },
        ...{ serviceName: "scenic" },
      };
    default:
      return state;
  }
};
