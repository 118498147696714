import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDispatch } from 'react-redux';

const SliderElement = ({
  steps,
  min,
  max,
  setTargetPlayerLatency,
  targetPlayerLatency,
}) => {
  //  "exponential step" curry function
  //   return tuple where
  //   [0] is the number of inputs we need our slider to have
  //   [1] is our output transform function
  const dispatch = useDispatch();
  const scaleTransform = (min, max, intervals) => {
    //determine how many "points" we need
    let distributions = intervals.length;
    let descretePoints = Math.ceil(
      (max - min) /
        intervals.reduce((total, step) => total + step / distributions, 0)
    );

    return [
      descretePoints,
      (input) => {
        let stepTransforms = intervals.map((s, i) => {
          let setCount = Math.min(
            Math.ceil(input - (descretePoints * i) / distributions),
            Math.round(descretePoints / distributions)
          );
          return setCount > 0 ? setCount * s : 0;
        });

        let lastStep = 0;
        let out =
          Math.round(
            stepTransforms.reduce((total, num, i) => {
              if (num) {
                lastStep = i;
              }
              return total + num;
            })
          ) + min;

        let currentUnit = intervals[lastStep];
        return Math.min(
          Math.round(out / currentUnit) * currentUnit, //round to nearest step
          max
        );
      },
    ];
  };
  let [points, sliderTransform] = scaleTransform(min, max, steps);

  const findValue = (needle) => {
    let latency = [];
    let val = [];
    for (let i = 0; i <= points; i++) {
      latency.push(sliderTransform(i));
      val.push(i);
    }

    let res = latency.reduce((a, b) => {
      return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
    });
    return val[latency.lastIndexOf(res)];
  };

  const [sliderValue, setSliderValue] = useState(
    findValue(targetPlayerLatency)
  );
  // eslint-disable-next-line
  useEffect(() => {
    setSliderValue(findValue(targetPlayerLatency));
    // eslint-disable-next-line
  }, [targetPlayerLatency]);

  return (
    <div>
      <Slider
        value={sliderValue}
        onChange={(e) => {
          setSliderValue(e);
          dispatch(
            setTargetPlayerLatency({ targetLatency: sliderTransform(e) })
          );
        }}
        min={0}
        max={points}
      />
    </div>
  );
};

export default SliderElement;
