const INITIAL_STATE = {
  DASH: {
    EU: {
      url01:
        'https://vn-public-krk-dmz-new.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.mpd',
      url02:
        'https://vn-public-krk-dmz-new.demo.synamedia.co.uk/content/cmaf/low-latency-travel/master.mpd',
    },
    NA: {
      url01:
        'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.mpd',
      url02:
        'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-travel/master.mpd',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/dash/low-latency/master.mpd',
      url02:
        'https://asia-low-latency.gke.synamedia.co.uk/content/dash/low-latency/master.mpd',
    },
  },
  'DASH Standard Latency': {
    EU: {
      url01:
        'https://vn-public-krk-dmz-new.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.mpd',
    },
    NA: {
      url01:
        'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.mpd',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/dash/std-latency/master.mpd',
    },
  },
  'HESP vs DASH': {
    EU: {
      url01:
        'https://vn-public-krk-dmz-new.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/single.mpd',
      url02:
        'https://vn-public-krk-dmz-new.demo.synamedia.co.uk/content/cmaf/low-latency-travel/single.mpd',
    },
    NA: {
      url01:
        'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/single.mpd',
      url02:
        'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-travel/single.mpd',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/dash/low-latency/master.mpd',
      url02:
        'https://asia-low-latency.gke.synamedia.co.uk/content/dash/low-latency-travel/master.mpd',
    },
  },
  HLS: {
    EU: {
      url01:
        'https://vn-public-krk-dmz-new.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.m3u8',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hlsmp4/low-latency/master.m3u8',
    },
    NA: {
      url01:
        'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.m3u8',
    },
  },
  HESP: {
    EU: {
      url01:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-01/master.json',
      url02:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-03/master.json',
    },
    NA: {
      url01:
        'https://vn-public-lwr-dmz.demo.synamedia.co.uk/hesp-01/master.json',
      url02:
        'https://vn-public-lwr-dmz.demo.synamedia.co.uk/hesp-03/master.json',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-01/hesp.json',
      url02:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-02/hesp.json',
    },
  },
  'Channel Zapping': {
    EU: {
      url01:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-01/master.json',
      url02:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-02/master.json',
      url03:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-03/master.json',
    },
    NA: {
      url01:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-01/master.json',
      url02:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-02/master.json',
      url03:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-03/master.json',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-01/hesp.json',
      url02:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-02/hesp.json',
      url03:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-03/hesp.json',
    },
  },
  'Multi Camera': {
    EU: {
      url01:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-04/master.json',
      url02:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-06/master.json',
      url03:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-05/master.json',
    },
    NA: {
      url01:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-04/master.json',
      url02:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-06/master.json',
      url03:
        'https://vn-public-krk-dmz-02.demo.synamedia.co.uk/hesp-05/master.json',
    },
    ASIA: {
      url01:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-04/hesp.json',
      url02:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-06/hesp.json',
      url03:
        'https://asia-low-latency.gke.synamedia.co.uk/content/hesp/hesp-05/hesp.json',
    },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'PLAYBACKURLS_LIST_CHANGE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
