import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Helmet } from 'react-helmet';

import {
  playback01UrlFromQuery,
  playback02UrlFromQuery,
  setPlayer01Parameters,
  setPlayer02Parameters,
} from '../../actions';
import { menuItems01, menuItems02 } from './UseCaseParameters';
import './FrontPage.css';
import Title from '../Title/Title';

const FrontPage = () => {
  const dispatch = useDispatch();
  const urlList = useSelector((state) => state.playbackUrlList);
  const { region } = useSelector((state) => state.player01Parameters);

  menuItems02['Channel Zapping'].link =
    region === 'NA' ? 'swiping-app/index.html' : 'swiping-app/index.html';
  menuItems02['Multi Camera'].link =
    region === 'NA' ? 'swiping-app/index.html' : 'swiping-app/index.html';

  const menuItems = {
    ...menuItems01,
    ...menuItems02,
  };

  const menuRender = () => {
    let menu = [];
    for (const item in menuItems) {
      menu.push(
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a key={item} className={menuItems[item].cssClassName}>
          {item}
        </a>
      );
    }

    return menu;
  };

  const [menu, setMenu] = useState(menuRender());
  const [content, setContent] = useState(<Title />);
  const [choosenMenuItem, setChoosenMenuItem] = useState();

  const setUseCase = (event) => {
    const menuItemName = event.target.innerHTML;

    if (menuItemName === choosenMenuItem) {
      for (const item in menuItems) {
        menuItems[item].cssClassName = 'item';
        setContent(<Title />);
        setChoosenMenuItem();
      }
    } else {
      for (const item in menuItems) {
        if (item === menuItemName) {
          menuItems[item].cssClassName = 'active item';
          setChoosenMenuItem(menuItemName);
        } else {
          menuItems[item].cssClassName = 'item';
        }
      }

      if (menuItems[menuItemName].link) {
        const url01 = urlList[menuItemName][region].url01;
        const url02 = urlList[menuItemName][region].url02;
        const url03 = urlList[menuItemName][region].url03;

        window.location = `${menuItems[menuItemName].link}?url=${url01},${url02},${url03}`;
      }

      if (menuItems[menuItemName].content) {
        setContent(menuItems[menuItemName].content);

        dispatch(setPlayer01Parameters(menuItems[menuItemName].player01));
        dispatch(setPlayer02Parameters(menuItems[menuItemName].player02));

        const usecase01 = menuItems[menuItemName].player01.usecase;
        const usecase02 = menuItems[menuItemName].player02.usecase;
        const urls01 = Object.keys(urlList[usecase01][region]);
        const urls02 = Object.keys(urlList[usecase02][region]);

        dispatch(playback01UrlFromQuery(urlList[usecase01][region][urls01[0]]));
        dispatch(playback02UrlFromQuery(urlList[usecase02][region][urls02[0]]));
      }
    }

    setMenu(menuRender());
  };

  return (
    <div>
      {/* <Helmet>
        <script
          type="text/javascript"
          //src="https://cdn.myth.theoplayer.com/185f9ef4-4604-446e-b027-106a1dd7bb39/THEOplayer.js"
          src="player/THEOplayer.js"
        ></script>
        <link
          rel="stylesheet"
          type="text/css"
          //href="https://cdn.myth.theoplayer.com/185f9ef4-4604-446e-b027-106a1dd7bb39/ui.css"
          href="player/ui.css"
        />
      </Helmet> */}
      <div className="container">
        <div className="flex-frontPage">
          <img alt="none" src="/img/synamedia-logo.gif" />
          <div className="content">
            <div className="ui top attached tabular menu" onClick={setUseCase}>
              {menu}
            </div>
            <div className="ui bottom attached active tab segment">
              {content}
            </div>
            {/* <p style={{ color: '#C0C0C0' }}>
              *for correct behavior use supported internet browsers:
              <br />
              <span style={{ position: 'relative', right: '-30px' }}>
                MAC OS: Google Chrome, Safari, FireFox and Microsoft Edge
                <br />
                Windows OS: Microsoft Edge
              </span>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
