import axios from 'axios';

const getlistUsers = () => {
  return new Promise((resolve) => {
    axios
      .get('v1/listUsers')
      .then((response) => {
        resolve(response.data.users);
      })
      .catch((e) => resolve(e));
  });
};

const updateUser = (user) => {
  return new Promise((resolve) => {
    axios({
      method: 'post',
      url: 'v1/updateUser',
      data: user,
    }).catch((e) => resolve(e));
  });
};

const deleteUser = (id) => {
  return new Promise((resolve) => {
    axios({
      method: 'delete',
      url: 'v1/deleteUser',
      data: {
        id,
      },
    }).catch((e) => resolve(e));
  });
};

const addUser = (user) => {
  return new Promise((resolve) => {
    axios({
      method: 'post',
      url: 'v1/addUser',
      data: user,
    }).catch((e) => resolve(e));
  });
};

export { getlistUsers, deleteUser, updateUser, addUser };
