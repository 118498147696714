import axios from 'axios';

const API_SERVER_URL = 'http://localhost:8443';
axios.defaults.baseURL = API_SERVER_URL;
axios.defaults.timeout = 100000;

const getpVMAFValuesForService = (serviceName, timeWindow) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/getpVMAFValuesForService',
      data: {
        serviceName,
        timeWindow,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });
};

const getpVMAFValuesForMultipleServices = (serviceName, timeWindow) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/getpVMAFValuesForMultipleServices',
      data: {
        serviceName,
        timeWindow,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });
};

const getStatisticsfromInfluxDB = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: '/getStatisticsfromInfluxDB',
    })
      .then((response) => resolve(response.data))
      .catch((e) => reject(e));
  });
};

export {
  getpVMAFValuesForService,
  getStatisticsfromInfluxDB,
  getpVMAFValuesForMultipleServices,
};