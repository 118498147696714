import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import './DashReferencePlayer.css';

const DashReferencePlayer = ({
  playerState,
  player,
  setPlayer,
  playerParameters,
  setPlayerParameters,
}) => {
  const dispatch = useDispatch();
  const element = useRef(null);
  const wallclockRef = useRef(null);

  const { timezoneName } = useSelector((state) => state[playerParameters]);
  const source = useSelector((state) => state[playerState].src);

  useEffect(() => {
    if (element.current) {
      var playerObject = new window.dashjs.MediaPlayer().create();

      playerObject.updateSettings({
        streaming: {
          delay: {
            liveDelay: 18,
          },
        },
      });

      playerObject.initialize(element.current, source, true);

      playerObject.setMute(true);

      setPlayer(playerObject);

      var wallclockUpdate = setInterval(() => {
        const now = moment().tz(timezoneName);
        if (!playerObject.isPaused() && wallclockRef.current) {
          wallclockRef.current.textContent = `${('00' + now.hour()).slice(
            -2
          )}:${('00' + now.minutes()).slice(-2)}:${('00' + now.seconds()).slice(
            -2
          )}:${('000' + now.millisecond()).slice(-3)}`;
        }
      }, 1);

      var playerMetricUpdate = setInterval(() => {
        if (!playerObject.isPaused()) {
          dispatch(
            setPlayerParameters({
              playbackRate: playerObject.getPlaybackRate().toFixed(2),
            })
          );
          dispatch(
            setPlayerParameters({
              currentLatency: `${Math.round(
                playerObject.getCurrentLiveLatency() * 1000
              )} ms`,
            })
          );
        } else {
          dispatch(
            setPlayerParameters({
              playbackRate: '',
            })
          );
        }
      }, 1000);
    }

    return () => {
      if (playerObject) {
        playerObject.destroy();
        setPlayer();
      }
      clearInterval(wallclockUpdate);
      clearInterval(playerMetricUpdate);
    };
    // eslint-disable-next-line
  }, []);

  const wallclockFontSize = () => {
    if (element.current) {
      const playerHeight = element.current.offsetHeight;
      const clockFontSize = Math.round(playerHeight / 18);
      wallclockRef.current.style.fontSize = `${clockFontSize}px`;
    }
  };

  return (
    <div className="dashreference">
      <div className="dashreference-player">
        <video
          style={{ width: '100%', height: '100%' }}
          ref={element}
          id="videoPlayer"
          controls
        >
          {wallclockFontSize()}
        </video>
      </div>
      <div className="dashreference-wallclock" ref={wallclockRef}></div>
    </div>
  );
};

export default DashReferencePlayer;
