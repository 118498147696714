import React, { useEffect, useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

import styles from './StartPage.module.css';
import { getEUMessage } from '../../api/messages';
import userpool from '../../userpool';

const StartPage = () => {
  const [messageForEurope, setMessageForEurope] = useState(null);
  const Navigate = useNavigate();
  const user = userpool.getCurrentUser();
  const logout = () => {
    if (user) {
      user.signOut();
      Navigate('/login');
    }
  };

  useEffect(() => {
    getEUMessage().then((d) => {
      if (d !== '') {
        setMessageForEurope(<div className={styles.warning}>{d}</div>);
      } else {
        setMessageForEurope(null);
      }
    });
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.row}>
          <img alt="none" src="/img/synamedia-logo.gif" />
          <div className={styles.logout}>
            <Button onClick={logout} size="mini">
              Logout
            </Button>
          </div>
        </div>
        <div className={styles.column}>
          <h3>LOW LATENCY DEMO</h3>
          <div className={styles.links}>
            <div>
              <a className="ui button" href="/#/na">
                Streams originated from North America
              </a>
            </div>
            <div>
              <a className="ui button" href="/#/eu">
                Streams originated from Europe
              </a>
              {messageForEurope}
            </div>
          </div>
          <div className={styles.links}>
            <div>
              <a className="ui button" href="/#/asia">
                Streams originated from Asia*
              </a>
              <div className={styles.note}>
                *in case streaming is inactive, please contact
                <br />
                <span>
                  <a href="mailto: vnpre-salesengineering@synamedia.com">
                    vnpre-salesengineering@synamedia.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartPage;
