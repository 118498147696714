import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popup, Dropdown } from 'semantic-ui-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './ControlElements.css';
import LatencySlider from '../LatencySlider/LatencySlider';
import { setPlayer01Parameters, setPlayer02Parameters } from '../../actions';

import {
  playback01UrlFromQuery,
  playback02UrlFromQuery,
  setZapperIndex,
} from '../../actions';

const ControlElements = () => {
  const dispatch = useDispatch();

  //player01
  const src01 = useSelector((state) => state.player01State.src);

  const copyToClipboard01 = () => {
    var textField = document.createElement('textarea');
    textField.innerText = src01;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  //qr code
  const qrCode01 = (
    <img
      alt="none"
      src={` https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${src01}`}
    />
  );

  //playback url and copy button
  const videoStreamLink01 = (
    <a className="video-link" href={src01} rel="noreferrer" target="_blank">
      video stream link
    </a>
  );

  //playback copy button
  const videoCopyButton01 = (
    <p onClick={copyToClipboard01}>copy url to clipboard</p>
  );

  const dropDownValue01 = [
    { key: 1, text: videoStreamLink01 },
    { key: 2, text: videoCopyButton01 },
  ];

  const player01ParametersState = useSelector(
    (state) => state.player01Parameters
  );

  // const srcArry = src01.split('.');
  //const ifHls = srcArry[srcArry.length - 1] === 'm3u8';

  const catchUPRate01 = useSelector(
    (state) => state.player01Parameters.catchUpRate
  );

  useEffect(() => {
    dispatch(setPlayer01Parameters({ catchUpRate: catchUPRate01 }));
    // eslint-disable-next-line
  }, [catchUPRate01]);

  ///////////////////////////////////////////////////////////////////////

  //player02
  const src02 = useSelector((state) => state.player02State.src);

  const copyToClipboard02 = () => {
    var textField = document.createElement('textarea');
    textField.innerText = src02;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  //qr code
  const qrCode02 = (
    <img
      alt="none"
      src={` https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${src02}`}
    />
  );

  //playback url and copy button
  const videoStreamLink02 = (
    <a className="video-link" href={src02} rel="noreferrer" target="_blank">
      video stream link
    </a>
  );

  //playback copy button
  const videoCopyButton02 = (
    <p onClick={copyToClipboard02}>copy url to clipboard</p>
  );

  const dropDownValue02 = [
    { key: 1, text: videoStreamLink02 },
    { key: 2, text: videoCopyButton02 },
  ];

  const player02ParametersState = useSelector(
    (state) => state.player02Parameters
  );
  const catchUPRate02 = useSelector(
    (state) => state.player02Parameters.catchUpRate
  );

  useEffect(() => {
    dispatch(setPlayer02Parameters({ catchUpRate: catchUPRate02 }));
    // eslint-disable-next-line
  }, [catchUPRate02]);

  ///////////////////////////////////////////////////////////////////////

  const urlList = useSelector((state) => state.playbackUrlList);
  const region01 = useSelector((state) => state.player01Parameters.region);
  const region02 = useSelector((state) => state.player02Parameters.region);
  const usecase01 = useSelector((state) => state.player01Parameters.usecase);
  const usecase02 = useSelector((state) => state.player02Parameters.usecase);

  const urls01 = Object.keys(urlList[usecase01][region01]);
  const urls02 = Object.keys(urlList[usecase02][region02]);
  let { zapperIndex } = useSelector((state) => state.zap);

  useEffect(() => {
    dispatch(
      playback01UrlFromQuery(urlList[usecase01][region01][urls01[zapperIndex]])
    );
    dispatch(
      playback02UrlFromQuery(urlList[usecase02][region02][urls02[zapperIndex]])
    );
    // eslint-disable-next-line
  }, [zapperIndex]);

  return (
    <div className="controlElementsBox">
      {/* Control Elements for the First Player */}
      <div className="flex-controlElement">
        <div className="controlElement-row-1">
          <Popup
            content={qrCode01}
            trigger={<p className="video-link">qr code</p>}
          />
          <Dropdown options={dropDownValue01} text="video stream link" />
          {player01ParametersState.zapElementVisible && (
            <button
              style={{ width: '150px' }}
              className="ui button"
              onClick={() => {
                dispatch(setZapperIndex());
              }}
            >
              zap
            </button>
          )}
        </div>
        <div className="controlElement-row-n">
          <div className="child-1">
            {player01ParametersState.latencySliderVisible && (
              <LatencySlider
                setTargetPlayerLatency={setPlayer01Parameters}
                targetPlayerLatency={player01ParametersState.targetLatency}
              />
            )}
          </div>

          <div>
            {player01ParametersState.playerVisible && (
              <p>
                {`Current player latency: ${player01ParametersState.currentLatency}`}
              </p>
            )}
            {player01ParametersState.playerVisible && (
              <p>{`Playback rate: ${player01ParametersState.playbackRate}`}</p>
            )}
            {player01ParametersState.zapElementVisible && (
              <p>{`Zapping Time: ${player01ParametersState.zappingTime}`}</p>
            )}
          </div>
        </div>
        <div className="controlElement-row-n">
          <div className="child-1">
            {player01ParametersState.catchUpRateSliderVisible && (
              <Slider
                value={catchUPRate01}
                onChange={(e) => {
                  dispatch(
                    setPlayer01Parameters({
                      catchUpRate: e,
                    })
                  );
                }}
                min={0.08}
                max={0.3}
                step={0.01}
              />
            )}
          </div>
          {player01ParametersState.catchUpRateSliderVisible && (
            <p>{`Latency CatchUp Rate: ${catchUPRate01} %`}</p>
          )}
        </div>
      </div>
      {/* Control Elements for the Second Player */}
      {player02ParametersState.playerVisible && (
        <div className="flex-controlElement">
          <div className="controlElement-row-1">
            <Popup
              content={qrCode02}
              trigger={<p className="video-link">qr code</p>}
            />
            <Dropdown options={dropDownValue02} text="video stream link" />
            {player02ParametersState.zapElementVisible && (
              <button
                style={{ width: '150px' }}
                className="ui button"
                onClick={() => {
                  dispatch(setZapperIndex());
                }}
              >
                zap
              </button>
            )}
          </div>
          <div className="controlElement-row-n">
            <div className="child-1">
              {player02ParametersState.latencySliderVisible && (
                <LatencySlider
                  setTargetPlayerLatency={setPlayer02Parameters}
                  targetPlayerLatency={player02ParametersState.targetLatency}
                />
              )}
            </div>

            <div>
              {player02ParametersState.playerVisible && (
                <p>
                  {`Current player latency: ${player02ParametersState.currentLatency}`}
                </p>
              )}
              {player02ParametersState.playerVisible && (
                <p>{`Playback rate: ${player02ParametersState.playbackRate}`}</p>
              )}
              {player02ParametersState.zapElementVisible && (
                <p>{`Zapping Time: ${player02ParametersState.zappingTime}`}</p>
              )}
            </div>
          </div>
          <div className="controlElement-row-n">
            <div className="child-1">
              {player02ParametersState.catchUpRateSliderVisible && (
                <Slider
                  value={catchUPRate02}
                  onChange={(e) => {
                    dispatch(
                      setPlayer02Parameters({
                        catchUpRate: e,
                      })
                    );
                  }}
                  min={0.08}
                  max={0.3}
                  step={0.01}
                />
              )}
            </div>
            {player02ParametersState.catchUpRateSliderVisible && (
              <p>{`Latency CatchUp Rate: ${catchUPRate02} %`}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlElements;
