const INITIAL_STATE = {
  targetLatency: 2000,
  catchUpRate: 0.08,
  playbackRate: '',
  bufferSize: '',
  currentLatency: '',
  zappingTime: '',
  header: 'DASH',
  region: 'NA',
  usecase: 'DASH',
  timezoneName: 'America/New_York',
  playerVisible: true,
  zapElementVisible: true,
  latencySliderVisible: true,
  catchUpRateSliderVisible: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PLAYER02_PARAMETERS':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
