import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import { getpVMAFValuesForMultipleServices } from "../../../api/qcvbr-server-api";

const ChartChart = () => {
  const [dataset_qcvbr, setDataset_qcvbr] = useState([]);
  const [dataset_cbr, setDataset_cbr] = useState([]);
  const chartData_qcvbr = useRef([]);
  const chartData_cbr = useRef([]);
  const chartRefreshRate = 5000;
  const timeWindowForData = "2m"; //30sec - 10 points of data
  const dataPointsToKeep = 40; //30sec - 10 points of data
  const timeData = Array.from({ length: dataPointsToKeep }, (_, i) => i + 1);

  const { segmentListQCVBR, segmentListCBR } = useSelector(
    (state) => state.segmentStatistics
  );
  const { serviceName } = useSelector((state) => state.qcvbrDemoPageVars);

  const data = {
    labels: timeData,
    datasets: [
      {
        label: "QC-VBR",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        data: dataset_qcvbr,
        pointRadius: 1,
        yAxisID: "y",
        cubicInterpolationMode: "monotone",
      },
      {
        label: "CBR",
        borderColor: "rgba(230,145,56, 0.8)",
        borderWidth: 2,
        data: dataset_cbr,
        pointRadius: 1,
        yAxisID: "y",
        cubicInterpolationMode: "monotone",
      },
      {
        label: "QC-VBR Segment Size",
        borderColor: "rgba(204,0,0, 0.8)",
        borderWidth: 2,
        data: segmentListQCVBR.map((e) => e.size),
        pointRadius: 1,
        yAxisID: "y1",
        cubicInterpolationMode: "monotone",
      },
      {
        label: "CBR Segment Size",
        borderColor: "rgba(56,118,29, 0.8)",
        borderWidth: 2,
        data: segmentListCBR.map((e) => e.size),
        pointRadius: 1,
        yAxisID: "y1",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "pVAMF statistcs for 1 min and segment size in real time",
      },
      legend: {
        display: true, // Set this to true to display the legend
        position: "bottom",
        labels: {
          boxWidth: 10, // Set boxWidth to 0 to hide the color boxes in the legend
          boxHeight: 1,
        },
      },
      tooltip: {
        mode: "nearest",
        intersect: false,
        // axis: 'y', // Set tooltip to display only the Y-axis value
        callbacks: {
          title: function (context) {
            if (
              context[0].dataset.label === "QC-VBR Segment Size" ||
              context[0].dataset.label === "CBR Segment Size"
            ) {
              return "size";
            } else {
              return "pVMAF";
            }
          },
          label: function (context) {
            if (
              context.dataset.label === "QC-VBR Segment Size" ||
              context.dataset.label === "CBR Segment Size"
            ) {
              return `${(context.parsed.y / 1024 / 1024).toFixed(2)} MB`; // Display the Y-axis value with two decimal places
            } else {
              return `${context.parsed.y.toFixed(2)}`; // Display the Y-axis value with two decimal places
            }
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        type: "linear",
        position: "bottom",
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        min: 70,
        max: 100,
        type: "linear",
        position: "left",
        grid: {
          display: false,
        },
      },
      y1: {
        type: "linear",
        position: "right",
        grid: {
          display: false,
        },
      },
    },
    // layout: {
    //   padding: 20,
    // },
  };
  const dataFiltering = (oldArray, newArray, pointsToKeep) => {
    const newData = _.differenceBy(newArray, oldArray, "_time");
    const updatedData = [...oldArray, ...newData];
    if (updatedData.length > pointsToKeep) {
      return _.drop(updatedData, updatedData.length - pointsToKeep);
    } else {
      return updatedData;
    }
  };

  const chartUpdate = () => {
    getpVMAFValuesForMultipleServices(serviceName, timeWindowForData)
      .then((d) => {
        const regex_qcvbr = new RegExp(`${serviceName}-qcvbr`);
        console.log("d", d);
        const data_qcvbr = _.filter(d, (element) => {
          if (regex_qcvbr.test(element.Service)) {
            return true;
          } else {
            return false;
          }
        });
        const data_cbr = _.difference(d, data_qcvbr);

        if (dataset_qcvbr.length === 0) {
          chartData_qcvbr.current = data_qcvbr;
        } else {
          chartData_qcvbr.current = dataFiltering(
            chartData_qcvbr.current,
            data_qcvbr,
            dataPointsToKeep
          );
        }

        if (dataset_cbr.length === 0) {
          chartData_cbr.current = data_cbr;
        } else {
          chartData_cbr.current = dataFiltering(
            chartData_cbr.current,
            data_cbr,
            dataPointsToKeep
          );
        }

        setDataset_qcvbr(chartData_qcvbr.current.map((item) => item._value));
        setDataset_cbr(chartData_cbr.current.map((item) => item._value));
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    chartUpdate();
    const chartUpdateInterval = setInterval(chartUpdate, chartRefreshRate);
    return () => {
      clearInterval(chartUpdateInterval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default ChartChart;
