import React, { useState, useEffect, useRef } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import isUrl from 'is-url';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import styles from './GeneralZappingDemo.module.css';
import TheoPlayer from '../TheoPlayer/TheoPlayer';
import ControlItems from '../FlexibleLinkPage/ControlItems';
import {
  playback01UrlFromQuery,
  setPlayer01Parameters,
  updatePlayerLicense,
} from '../../actions';

const GeneralZappingDemo = () => {
  const dispatch = useDispatch();
  const [playerHtml, setPlayerHtml] = useState();
  const [player, setPlayer] = useState();
  const dashUrl01 =
    'http://52.56.98.109:8092/dash/SkyMainEvent/MainEventCMAF.mpd';
  const dashUrl02 = 'http://52.56.98.109:8092/dash/SkyPremLge/PremLgeCMAF.mpd';
  const baseUrl = '/sky/zap/channel-zapping?url=';
  const { src } = useSelector((state) => state.player01State);

  const ref = useRef({
    lastInputElementId: 1,
  });
  const urlList = useRef({});

  const updateValue = (event) => {
    urlList.current = {
      ...urlList.current,
      [event.target.id]: event.target.value,
    };
  };

  const [inputList, setInputList] = useState([
    <div key={ref.current.lastInputElementId}>
      <Input
        style={{ marginTop: '10px' }}
        id={ref.current.lastInputElementId}
        onChange={(event) => updateValue(event)}
        fluid
        icon
        placeholder="URL..."
      />
    </div>,
  ]);

  const handleAdd = () => {
    ref.current.lastInputElementId += 1;
    setInputList([
      ...inputList,
      <div key={ref.current.lastInputElementId}>
        {
          <Input
            style={{ marginTop: '10px' }}
            id={ref.current.lastInputElementId}
            onChange={(event) => updateValue(event)}
            fluid
            icon
            placeholder="URL..."
          />
        }
      </div>,
    ]);
  };

  const handleRemove = () => {
    if (inputList.length > 1) {
      urlList.current = _.omit(urlList.current, ref.current.lastInputElementId);
      ref.current.lastInputElementId -= 1;
      setInputList(_.dropRight(inputList, 1));
    }
  };

  const openPlayer = () => {
    var checkedUrlList = [];
    for (let i in urlList.current) {
      if (isUrl(urlList.current[i])) {
        const re = /.+\.(json)|(mpd)$/;
        if (re.test(urlList.current[i])) {
          checkedUrlList.push(urlList.current[i]);
        }
      }
    }
    if (checkedUrlList.length > 0) {
      const url = baseUrl + checkedUrlList;

      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const openFixedLink = (url) => {
    dispatch(playback01UrlFromQuery(url));
    dispatch(
      setPlayer01Parameters({
        targetLatency: 2000,
        region: 'EU',
        timezoneName: 'Europe/London',
        usecase: 'DASH',
        latencySliderVisible: true,
        header: 'DASH',
      })
    );

    if (!playerHtml || (playerHtml && url !== src)) {
      if (url === dashUrl01) {
        dispatch(
          setPlayer01Parameters({
            usecase: 'DASH 1',
          })
        );
      } else {
        dispatch(
          setPlayer01Parameters({
            usecase: 'DASH 2',
          })
        );
      }
      setPlayerHtml(
        <div className={styles.grid}>
          <div className={styles.player}>
            <TheoPlayer
              playerState={'player01State'}
              player={player}
              setPlayer={setPlayer}
              playerParameters={'player01Parameters'}
              setPlayerParameters={setPlayer01Parameters}
            />
          </div>
          <div className={styles.control}>
            <ControlItems
              playerState={'player01State'}
              setPlayerParameters={setPlayer01Parameters}
              playerParameters={'player01Parameters'}
            />
          </div>
        </div>
      );
    } else {
      setPlayerHtml();
    }
  };

  useEffect(() => {
    dispatch(
      updatePlayerLicense(
        'https://portal.theoplayer.com/app/ba9a9732-4f25-4f82-9aca-6c2e3544bb9b/players/aa828fab-429c-49c1-8d16-60e20a607729',
        'sZP7IYe6T6P60Sft3ofz0Ozt3l5zFSaz3l5-TSeL3ZzkTQ0t3uCzCS0i0lf6FOPlUY3zWokgbgjNIOf9fKP1TDBr3L0ZFSxK0lh-3QC_06zrCD31FSIl0KhL3SazCKfrC6fVfK4_bQgZCYxNWoryIQXzImf90SCt3S5t0lRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lacTSRZ0lBc3LBcFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz'
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.player = player;
  }, [player]);

  return (
    <div className={styles.container}>
      <Helmet>
        <script src="/THEOplayerOlympics/THEOplayer.js" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/THEOplayerOlympics/ui.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
        />
      </Helmet>
      <div className={styles.companyLogos}>
        <img alt="none" src="/img/synamedia-logo.gif" />
        <img alt="none" src="/img/theo-logo-rgb.jpg" />
      </div>
      <div
        style={{ margin: '2rem 0px 0px 00px', width: '50vw' }}
        className="ui bottom attached active tab segment"
      >
        <Button.Group>
          <Button
            disabled={ref.current.lastInputElementId === 1}
            icon="minus"
            onClick={handleRemove}
          />
          <Button
            //   disabled={items.length === users.length}
            icon="plus"
            onClick={handleAdd}
          />
        </Button.Group>
        {inputList}
        <div className={styles.buttonGroup}>
          <Button onClick={openPlayer} style={{ marginTop: '30px' }} primary>
            Open Player
          </Button>
          <div className={styles.buttonFixLinks}>
            <Button
              onClick={() => {
                openFixedLink(dashUrl01);
              }}
              primary
            >
              DASH link #1
            </Button>
            <Button
              onClick={() => {
                openFixedLink(dashUrl02);
              }}
              primary
            >
              DASH link #2
            </Button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>{playerHtml}</div>
    </div>
  );
};

export default GeneralZappingDemo;
