import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import styles from "./QCVBR.module.scss";
import LineChart from "./Chart/Line";
import BarChart from "./Chart/Bar";
import {
  updateSegmentListQCVBR,
  updateSegmentListCBR,
  setCurrentServiceQCVBRDemo,
} from "../../actions";

const QCVBR = () => {
  const dispatch = useDispatch();
  const video_qcvbr = useRef(null);
  const video_cbr = useRef(null);

  // dispatch(setCurrentServiceQCVBRDemo("scenic"));

  const { url_qcvbr, url_cbr } = useSelector(
    (state) => state.qcvbrDemoPageVars
  );

  const [sliderValue, setSliderValue] = useState(50); // Initial slider value

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const onFragmentLoadingCompletedQCVBR = (event) => {
    if (event.request.mediaType === "video" && event.request.duration) {
      var temp = event.request.bytesTotal;
      dispatch(updateSegmentListQCVBR(event.request.bytesTotal));
    }
  };

  const onFragmentLoadingCompletedCBR = (event) => {
    if (event.request.mediaType === "video" && event.request.duration) {
      var temp = event.request.bytesTotal;
      dispatch(updateSegmentListCBR(event.request.bytesTotal));
    }
  };

  useEffect(() => {
    let player_qcvbr;
    let player_cbr;
    let playerDataInterval;
    if (
      video_qcvbr.current &&
      video_cbr.current &&
      !player_cbr &&
      !player_qcvbr
    ) {
      player_qcvbr = window.dashjs.MediaPlayer().create();

      player_qcvbr.on(
        window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
        onFragmentLoadingCompletedQCVBR
      );

      player_qcvbr.initialize(video_qcvbr.current, url_qcvbr, true);
      player_qcvbr.setMute(true);

      player_cbr = window.dashjs.MediaPlayer().create();

      player_cbr.on(
        window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
        onFragmentLoadingCompletedCBR
      );

      player_cbr.initialize(video_cbr.current, url_cbr, true);
      player_cbr.setMute(true);

      window.player_qcvbr = player_qcvbr;
      window.player_cbr = player_cbr;
    }
    return () => {
      if (player_qcvbr) {
        player_qcvbr.reset();

        window.player_qcvbr = null;
      }
      if (player_cbr) {
        player_cbr.reset();

        window.player_cbr = null;
      }
      clearInterval(playerDataInterval);
    };
  }, []);

  return (
    <div className={styles.box}>
      <div className={styles.row}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.window}>
              <div className={styles.title}>QC VBR streaming</div>
              <video className={styles.main} ref={video_qcvbr} controls></video>
            </div>
            <div className={styles.chart_window}>
              <div className={styles.chart_main}>
                <LineChart />
              </div>
              <div className={styles.chart_bottom}></div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.window}>
              <div className={styles.title}>CBR streaming</div>
              <video className={styles.main} ref={video_cbr} controls></video>
            </div>
            <div className={styles.chart_window}>
              <div className={styles.chart_main}>
                <BarChart />
                <div className={styles.chart_bottom}>
                  *Mode - value that appears most frequently in a set of data
                  <br />
                  *Total Size - total segmets size downloaded by player while
                  playback is running
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Slider
          vertical
          min={0}
          max={100}
          value={sliderValue}
          onChange={handleSliderChange}
          style={{ width: '100px', height: '500px' }}
        /> */}
      </div>
    </div>
  );
};

export default QCVBR;
