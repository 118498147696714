import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  Routes,
  Route,
  HashRouter,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';

import reducer from './reducers';
import StartPage from './components/StartPage/StartPage';
import App from './components/App';
import FlexibleLinkPage from './components/FlexibleLinkPage/FlexibleLinkPage';
import GeneralZappingDemo from './components/GeneralZappingDemo/GeneralZappingDemo';
import Users from './components/Users/Users';
import QCVBR from './components/QCVBR/QCVBR';
import Login from './components/Authentication/Login';

// Authentication
import userpool from './userpool';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const RequireAuth = ({ children }) => {
  const hostName = window.location.hostname;
  const user = userpool.getCurrentUser();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <StartPage />
            </RequireAuth>
          }
        />
        <Route
          path="/na"
          element={
            <RequireAuth>
              <App region="na" />
            </RequireAuth>
          }
        />
        <Route
          path="/eu"
          element={
            <RequireAuth>
              <App region="eu" />
            </RequireAuth>
          }
        />
        <Route
          path="/asia"
          element={
            <RequireAuth>
              <App region="asia" />
            </RequireAuth>
          }
        />
        <Route
          path="/demo"
          element={
            <RequireAuth>
              <FlexibleLinkPage />
            </RequireAuth>
          }
        />
        <Route
          path="/sky/zap"
          element={
            <RequireAuth>
              <GeneralZappingDemo />
            </RequireAuth>
          }
        />
        <Route path="/qcvbr" element={<QCVBR />} />
        <Route path="/users" element={<Users />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </HashRouter>
  </Provider>
);
