/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import styles from './FlexibleLinkPage.module.css';
import UrlInput from './UrlInput';
import TheoPlayer from '../TheoPlayer/TheoPlayer';
import {
  playback01UrlFromQuery,
  setPlayer01Parameters,
  updatePlayerLicense,
} from '../../actions';

import {
  dashPlaybackUrl01,
  dashPlaybackUrl02,
  hlsUrlPlaybackUrl01,
  hlsUrlPlaybackUrl02,
  hespUrlFastly01,
  hespUrlFastly02,
} from './Olympics';
import ControlItems from './ControlItems';

const FlexibleLinkPage = () => {
  const dispatch = useDispatch();
  const [dashMenuClass1, setDashMenuClass1] = useState('item');
  const [dashMenuClass2, setDashMenuClass2] = useState('item');
  const [hlsMenuClass1, setHlsMenuClass1] = useState('item');
  const [hlsMenuClass2, setHlsMenuClass2] = useState('item');
  const [hespMenuClass1, setHespMenuClass1] = useState('item');
  const [hespMenuClass2, setHespMenuClass2] = useState('item');
  const [choosenMenuItem, setChoosenMenuItem] = useState();
  const [player, setPlayer] = useState();

  const { dashPlaybackUrl, hlsPlaybackUrl, hespPlaybackUrl, timezone } =
    useSelector((state) => state.playbackUrlsInputElement);

  //update player license

  useEffect(() => {
    dispatch(
      updatePlayerLicense(
        'https://portal.theoplayer.com/app/ba9a9732-4f25-4f82-9aca-6c2e3544bb9b/players/aa828fab-429c-49c1-8d16-60e20a607729',
        'sZP7IYe6T6P60Sft3ofz0Ozt3l5zFSaz3l5-TSeL3ZzkTQ0t3uCzCS0i0lf6FOPlUY3zWokgbgjNIOf9fKP1TDBr3L0ZFSxK0lh-3QC_06zrCD31FSIl0KhL3SazCKfrC6fVfK4_bQgZCYxNWoryIQXzImf90SCt3S5t0lRi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lacTSRZ0lBc3LBcFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz'
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUseCase = (event) => {
    const menuItemName = event.target.innerHTML;

    if (menuItemName === choosenMenuItem) {
      setChoosenMenuItem();
      setDashMenuClass1('item');
      setDashMenuClass2('item');
      setHlsMenuClass1('item');
      setHlsMenuClass2('item');
      setHespMenuClass1('item');
      setHespMenuClass2('item');
      return;
    }
    // eslint-disable-next-line default-case
    switch (menuItemName) {
      case 'DASH 1':
        setChoosenMenuItem('DASH 1');
        setDashMenuClass1('item active');
        setDashMenuClass2('item');
        setHlsMenuClass1('item');
        setHlsMenuClass2('item');
        setHespMenuClass1('item');
        setHespMenuClass2('item');
        if (dashPlaybackUrl) {
          dispatch(playback01UrlFromQuery(dashPlaybackUrl));
        } else {
          dispatch(playback01UrlFromQuery(dashPlaybackUrl01));
        }
        dispatch(
          setPlayer01Parameters({
            targetLatency: 2000,
            region: 'EU',
            timezoneName: timezone,
            usecase: 'DASH',
            latencySliderVisible: true,
            header: 'DASH 1',
          })
        );

        break;
      case 'DASH 2':
        setChoosenMenuItem('DASH 2');
        setDashMenuClass1('item');
        setDashMenuClass2('item active');
        setHlsMenuClass1('item');
        setHlsMenuClass2('item');
        setHespMenuClass1('item');
        setHespMenuClass2('item');

        if (dashPlaybackUrl) {
          dispatch(playback01UrlFromQuery(dashPlaybackUrl));
        } else {
          dispatch(playback01UrlFromQuery(dashPlaybackUrl02));
        }
        dispatch(
          setPlayer01Parameters({
            targetLatency: 2000,
            region: 'EU',
            timezoneName: timezone,
            usecase: 'DASH',
            latencySliderVisible: true,
            header: 'DASH 2',
          })
        );

        break;

      case 'HLS 1':
        setChoosenMenuItem('HLS 1');
        setDashMenuClass1('item');
        setDashMenuClass2('item');
        setHlsMenuClass1('item active');
        setHlsMenuClass2('item');
        setHespMenuClass1('item');
        setHespMenuClass2('item');

        if (hlsPlaybackUrl) {
          dispatch(playback01UrlFromQuery(hlsPlaybackUrl));
        } else {
          dispatch(playback01UrlFromQuery(hlsUrlPlaybackUrl01));
        }
        dispatch(
          setPlayer01Parameters({
            targetLatency: 3000,
            region: 'EU',
            timezoneName: timezone,
            usecase: 'HLS',
            latencySliderVisible: true,
            header: 'HLS 1',
          })
        );

        break;
      case 'HLS 2':
        setChoosenMenuItem('HLS 2');
        setDashMenuClass1('item');
        setDashMenuClass2('item');
        setHlsMenuClass1('item');
        setHlsMenuClass2('item active');
        setHespMenuClass1('item');
        setHespMenuClass2('item');

        if (hlsPlaybackUrl) {
          dispatch(playback01UrlFromQuery(hlsPlaybackUrl));
        } else {
          dispatch(playback01UrlFromQuery(hlsUrlPlaybackUrl02));
        }
        dispatch(
          setPlayer01Parameters({
            targetLatency: 3000,
            region: 'EU',
            timezoneName: timezone,
            usecase: 'HLS',
            latencySliderVisible: true,
            header: 'HLS 2',
          })
        );

        break;
      case 'HESP 1':
        setChoosenMenuItem('HESP 1');
        setDashMenuClass1('item');
        setDashMenuClass2('item');
        setHlsMenuClass1('item');
        setHlsMenuClass2('item');
        setHespMenuClass1('item active');
        setHespMenuClass2('item');

        if (hespPlaybackUrl) {
          dispatch(playback01UrlFromQuery(hespPlaybackUrl));
        } else {
          dispatch(playback01UrlFromQuery(hespUrlFastly01));
        }
        dispatch(
          setPlayer01Parameters({
            targetLatency: 500,
            region: 'EU',
            timezoneName: timezone,
            usecase: 'HESP',
            latencySliderVisible: false,
            header: 'HESP 1',
          })
        );

        break;
      case 'HESP 2':
        setChoosenMenuItem('HESP 2');
        setDashMenuClass1('item');
        setDashMenuClass2('item');
        setHlsMenuClass1('item');
        setHlsMenuClass2('item');
        setHespMenuClass1('item');
        setHespMenuClass2('item active');

        if (hespPlaybackUrl) {
          dispatch(playback01UrlFromQuery(hespPlaybackUrl));
        } else {
          dispatch(playback01UrlFromQuery(hespUrlFastly02));
        }
        dispatch(
          setPlayer01Parameters({
            targetLatency: 500,
            region: 'EU',
            timezoneName: timezone,
            usecase: 'HESP',
            latencySliderVisible: false,
            header: 'HESP 2',
          })
        );

        break;
    }
  };

  useEffect(() => {
    window.player = player;
    if (choosenMenuItem === 'HESP 1' || choosenMenuItem === 'HESP 2') {
      player.hesp.latency = {
        windowStart: 0.6,
        target: 0.85,
        windowEnd: 1.1,
        maxOffset: 2,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const renderPlayer = () => {
    if (choosenMenuItem) {
      return (
        <div className={styles.grid}>
          <div className={styles.player}>
            <TheoPlayer
              playerState={'player01State'}
              player={player}
              setPlayer={setPlayer}
              playerParameters={'player01Parameters'}
              setPlayerParameters={setPlayer01Parameters}
            />
          </div>
          <div className={styles.control}>
            <ControlItems
              playerState={'player01State'}
              setPlayerParameters={setPlayer01Parameters}
              playerParameters={'player01Parameters'}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.greybox}>
          <i className={`fas fa-snowboarding fa-10x ${styles.tvbox}`}></i>
          <i className={`fas fa-skiing fa-10x ${styles.tvbox}`}></i>
          <i className={`fas fa-skiing-nordic fa-10x ${styles.tvbox}`}></i>
          <i className={`fas fa-skating fa-10x ${styles.tvbox}`}></i>
        </div>
      );
    }
  };

  return (
    <div>
      <Helmet>
        <script src="/THEOplayerOlympics/THEOplayer.js" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/THEOplayerOlympics/ui.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.companyLogos}>
          <img alt="none" src="/img/synamedia-logo.gif" />
          <img alt="none" src="/img/theo-logo-rgb.jpg" />
        </div>
        <div className={styles.links}>
          <UrlInput />
        </div>
        <div className={styles.main}>
          <div className="ui top attached tabular menu" onClick={setUseCase}>
            <a className={dashMenuClass1}>DASH 1</a>
            <a className={dashMenuClass2}>DASH 2</a>
            <a className={hlsMenuClass1}>HLS 1</a>
            <a className={hlsMenuClass2}>HLS 2</a>
            <a className={hespMenuClass1}>HESP 1</a>
            <a className={hespMenuClass2}>HESP 2</a>
          </div>
          <div className="ui bottom attached active tab segment">
            {renderPlayer()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlexibleLinkPage;
