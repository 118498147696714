import React from 'react';

import './Title.css';

const Title = () => {
  return (
    <div className="flex-title-container">
      <div className="row">
        <div className="column-1">
          <img alt="none" src="/img/vivid-logo.svg" />
          <h2>OTT Low Latency Broadcast equivalent streaming</h2>
        </div>
        <div className="column-2">
          <h2>VIVID Low Latency Streaming</h2>
        </div>
      </div>
      <img alt="none" src="/img/title9.png" />
    </div>
  );
};

export default Title;
